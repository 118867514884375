@import '../../scss/theme-bootstrap';

.elc-cart-overlay-sidebar {
  .elc-overlay-sidebar {
    z-index: 2147483647;

    &.elc-overlay-sidebar-slidein {
      width: 100%;

      @media #{$large-up} {
        width: 410px;
      }
    }

    &.elc-overlay-sidebar-slideout {
      width: 0;
    }

    .elc-cart-sidebar-close-icon {
      width: 25px;
      height: 25px;
      margin-#{$rdirection}: 10px;

      @media #{$large-up} {
        width: 20px;
        height: 20px;
      }
    }
  }

  .elc-overlay-shadow.elc-overlay-shadow-visible {
    z-index: 2147483646;
  }
}

.elc-cart-item {
  &-price-wrapper {
    p {
      font-size: 12px;
      font-family: $akzidenz;
      letter-spacing: 1.5px;
      color: $color-blue-dark;

    }

    .elc-subtitle--2 {

      &.elc-cart-item-total,
      &.elc-cart-item-free-price,
      &.elc-cart-item-sample-price {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }


  &-description-product-quantity {

    .elc-icon.elc-spinner-plus-icon,
    .elc-icon.elc-spinner-minus-icon {
      height: 11px;
      width: 11px;
    }

    div &-spinner-selector {
      border-radius: 0;

      .elc-subtitle--1 {
        font-family: $akzidenz;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }

  div &-panel {
    align-items: flex-start;
  }

  &-image {
    &>img {
      width: 80px;
    }
  }

  &-description-link {
    p {
      font-family: $optimaregular;
      color: $color-blue-dark;
      letter-spacing: 0;
      font-weight: 600;
      max-width: 240px;
      line-height: get-line-height(15px, 28px);
    }

    .elc-subtitle--2 {
      font-size: 18px;
    }

    .elc-body--2 {
      font-size: 14px;
      line-height: 1.15;
    }
  }

  &-description-product-wrapper {
    p.elc-medium-emphasis {
      color: $color-blue-dark;
    }

    &-shade {
      text-transform: uppercase;
    }
  }

  &-engrave {
    p.elc-subtitle--2 {
      font-family: $akzidenz;
      color: $color-blue-dark;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }

    &-message {
      font-size: 20px;

      .engrave-choice-optima {
        font-family: $optima;
      }

      .engrave-choice-roman {
        font-family: $roman;
      }

      .engrave-choice-script {
        font-family: $script;
      }
    }
  }
}

button.elc-cart-item-remove {
  font-family: $akzidenz;
  border-bottom: none;
  text-decoration: underline;
  color: $color-blue-dark;
}

.elc-cart-sidebar {
  &-header {
    padding-#{$ldirection}: 16px;
  }

  &-title>p {
    color: $color-navy;
    font-family: $optimalight;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
  }

  &-counter>span {
    color: $color-white;
    font-family: $optimaLT;
    font-size: 11px;
    background-color: $color-blue-dark;
    height: 20px;
    width: 20px;
    line-height: get-line-height(11px, 20px);
    letter-spacing: 0;
  }

  &-sub-total>p {
    font-family: $akzidenz;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: get-line-height(12px, 16px);
    letter-spacing: 1.8px;
    color: $color-blue-dark;

    &:last-child {
      letter-spacing: 2.1px;
    }
  }

  [data-component] &-checkout-button.elc-button,
  [data-component] &-go-shopping-button.elc-button {
    border-radius: 0;
    color: $color-white;
    background-color: $color-blue-dark;
    height: 48px;
    font-weight: bold;

    >div.elc-lock-icon {
      width: 24px;
      height: 24px;
      -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' preserveAspectRatio='none' fill='%23000000' fill-opacity='1' stroke-width='1px' %3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z' /%3E%3C/svg%3E") center center no-repeat;
    }

    >p.elc-subtitle--2 {
      font-family: $akzidenz;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 1.8px;
    }
  }
}

.footer-legal {
  .level-1 {
    span {
      display: none;
    }
  }
}
